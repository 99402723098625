import React from 'react';
import Layout from "../components/layout"
import me from '../assets/me.jpg'
import '../components/about.css'

const AboutPage = () => (
  <Layout>
    <div className='info-container'>
      <div className='conatainer'>
        <h1>My Journey</h1>
          <p className='p1'>
            Hi, I'm currently a Freelance JavaScript Developer and Graphic Designer.
          </p>
          <p className='p1'>
            I attended the University of Colorado Denver where I took credits towards a Bachelor’s of Science in Psychology. I eventually found a different calling in life and moved to Phoenix, Arizona to attend a Web Development bootcamp to learn coding. I fell in love with coding because of all the ever-growing possibilities in technology. Since I have experience in Photoshop and Illustrator editing and creating from scratch, I have a sense for what is appealing to a user’s eye. I am able to combine this with my experience in HTML/CSS/JavaScript and React to make visually appealing web apps.
          </p>
          <p className='p1'>
            I am currently spending my time carefully reviewing job postings, reaching out to connections while also trying to continue and expand my knowledge in different areas of programming. I am currently taking Meta's Backend Development course through Coursera to improve my knowledge of the backend.
          </p>
          <p className='p1'> 
            I have worked on several web apps both by myself and with a team. I am proficient in React, Node, Express, and PostgreSQL as the majority of my apps have been built off of those. I believe that my experience working with and without a team has made me a better developer because I can develop a web app by myself or I can create code that will function with others code while delivering a viable product on short deadlines. I also learned how to resolve conflicts with other team members in a professional manner.
          </p>
          <p className='p1'>
            I spent some time as a Software Developer Intern for Whistle Messaging Inc. Being able to be a software developer at a small startup I get exposure to all parts of the code is amazing to continually learn new technologies. I worked on feature additions, bug fixes, and refactoring code. I was involved in daily standups with the co-founders and lead engineer.
          </p>
          <p className='p1'>
            My last two positions I was an Implementation Specialist II and Implementation Engineer for Inovalon and Shoptelligence. I wore many hats while working in both these positions and got to learn a lot. I fulfilled multiple roles as implementation engineer, project manager, client success manager, and technical support. I also was able to utilize my coding abilities by updating and maintaining MySQL databases while utilizing JavaScript, HTML, and CSS to write scripts for Google Tag Manager.
          </p>
          <p className='p1'>
            I am searching for a role as either a developer, implementation engineer, or technical project manager. I am currently located in Colorado but I am more than happy to relocate for the right role or continue to work remote. I believe my combination of experience and passion to continually learn and improve myself will make me a great asset to any type of company.
          </p>
          <img src={me} alt="Kevin Tran" className='me'/>
        </div>
      </div> 
  </Layout>
  );

export default AboutPage;